<form [formGroup]="form" class="mt-4">
	<div class="row mb-3">
		<div class="col-md-4">
			<app-modular-form-control-input name="reference"></app-modular-form-control-input>
		</div>
		<div class="col-md-4">
			<app-modular-form-control-input name="usualDesignation"></app-modular-form-control-input>
		</div>
	</div>

	<div class="row mb-3">
		<div class="col-md-4">
			<app-modular-form-control-input name="type"></app-modular-form-control-input>
		</div>
		<div class="col-md-4">
			<app-modular-form-control-multiselect name="programs" [options]="programs$ | async" [showTooltip]="true"></app-modular-form-control-multiselect>
		</div>
		<div class="col-md-4">
			<app-modular-form-control-input name="offset" type="number" [showTooltip]="true" *permissionsAllowed="['VIEW_ARTICLE_PARAMETERS']"></app-modular-form-control-input>
		</div>
	</div>

	<div class="row mb-3">
		<div class="col-md-4">
			<app-modular-form-control-multiselect name="projects" [options]="projects$ | async"></app-modular-form-control-multiselect>
		</div>
	</div>

	<hr>
	<br>

	<div class="row mb-3">
		<div class="col-md-4">
			<app-modular-form-control-checkbox name="tailored"></app-modular-form-control-checkbox>
		</div>
		<div class="col-md-4">
			<app-modular-form-control-input *ngIf="getControlValue('tailored')" name="tailoredCode"></app-modular-form-control-input>
		</div>
	</div>

	<div class="row mb-3">
		<div class="col-md-4">
			<app-modular-form-control-select name="sourceRadicalIdentifierUuid" [options]="sourceRadicalIdentifiers$ | async"></app-modular-form-control-select>
		</div>
	</div>

	<div class="row mb-3">
		<div class="col-md-4">
			<app-modular-form-control-select name="flightManagementSystem" [options]="flightManagementSystems$ | async"></app-modular-form-control-select>
		</div>

		<div class="col-md-4">
			<app-modular-form-control-input name="databaseRadicalIdentifier" type="text"></app-modular-form-control-input>
		</div>
	</div>

	<ng-container *ngIf="getControlValue('flightManagementSystem')">
		<div class="row mb-3">
			<div class="col-md-4">
				<app-modular-form-control-input name="dqrReference"></app-modular-form-control-input>
			</div>
			<div class="col-md-4">
				<app-modular-form-control-input name="compatibleApp"></app-modular-form-control-input>
			</div>
		</div>
	</ng-container>

	<div class="row mb-3">
		<div class="col-md-4">

			<div class="row">
				<div class="col-12 mb-3">
					<app-modular-form-control-select name="rnpAr" [options]="rnpArs"></app-modular-form-control-select>
				</div>

				<div class="col-12 mb-3">
					<app-modular-form-control-checkbox name="lpv"></app-modular-form-control-checkbox>
				</div>

				<div class="col-12">
					<app-modular-form-control-select name="exportControl" [options]="exportControls"></app-modular-form-control-select>
				</div>

			</div>


		</div>
		<div class="col-md-4">
			<app-modular-form-control-select name="geoAreaType" [options]="geoAreasTypes$ | async"></app-modular-form-control-select>

			<ng-container *ngIf="getControlValue('geoAreaType') === 'REGIONAL' ||getControlValue('geoAreaType') === 'REGIONAL_AREA'">
				<app-modular-form-control-multiselect name="geoAreas" [options]="(geoAreas$ | async)?.[getControlValue('geoAreaType')]" [hideLabel]="true"></app-modular-form-control-multiselect>
			</ng-container>

			<ng-container *ngIf="getControlValue('geoAreaType') === GEOMETRIC_AREA">
				<div *ngFor="let x of getGeometricControls(); let i = index">
					<div class="d-flex align-items-center mb-3 flex-nowrap gap-2">
						<app-modular-form-control-input [name]="'geometricLocations.' + i + '.latitude'"
														layout="flex-inline"
														[hideLabel]="true"/>
						<app-modular-form-control-input [name]="'geometricLocations.' + i + '.longitude'"
														layout="flex-inline"
														[hideLabel]="true"/>
						<i class="ti ti-trash float-end text-danger me-2 fs-4 pointer"
						   *ngIf="!form.disabled"
						   (click)="removeGeometricControl(i)">
						</i>
					</div>
				</div>
				<div class="invalid-feedback d-block mb-2" id="invalid-feedback-geoLocation" *ngIf="this.form.get('geometricLocations').errors !== null">
            		<span *ngFor="let error of getGeometricErrors()">
                		{{ 'article.form.geometricLocations.validation.' + error | translate }}
            		</span>
				</div>
				<button type="button" (click)="addGeometricControl()"
						id="add-geoLocation"
						*ngIf="!form.disabled"
						class="btn btn-secondary mb-2">{{ 'article.form.geometricLocations.add' | translate }}
				</button>
			</ng-container>
			<ng-container *ngIf="getControlValue('geoAreaType') === REGIONAL_SOURCE">
				<app-modular-form-control-input [name]="'regionalCode'" [hideLabel]="true"/>
				<app-modular-form-control-input [name]="'regionalCodeDefinition'" [hideLabel]="true"/>
			</ng-container>
			<ng-container *ngIf="getControlValue('geoAreaType') === COUNTRIES">
				<app-modular-form-control-input [name]="'icaoCountries'" [hideLabel]="true"/>
			</ng-container>
		</div>
	</div>


	<ng-container *ngIf="article">
		<app-modular-form-control-static-list name="flightDatabases" *permissionsAllowed="['VIEW_ARTICLE_PARAMETERS']"></app-modular-form-control-static-list>
		<app-modular-form-control-static-list name="customers" *permissionsAllowed="['VIEW_ARTICLE_PARAMETERS']"></app-modular-form-control-static-list>
	</ng-container>

	<app-invalid-feedback></app-invalid-feedback>
</form>
