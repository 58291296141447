<app-modular-form-control-layout [parent]="this">
	<input [id]="id()"
		   [type]="type"
		   class="form-control"
		   [class.form-control-small]="small"
		   [placeholder]="translatePlaceholder()"
		   [formControl]="formControl"
		   [class.has-validation]="hasErrors()"
		   [class.is-invalid]="hasErrors()">
</app-modular-form-control-layout>
