import {AbstractControl, ValidationErrors} from '@angular/forms';
import {Cycle, InvalidCycleIdentifierError} from 'airac-cc';
import {ICAOCountry} from '../../icao/_model/icao-country';

export class CustomValidators {
	static validAirac(control: AbstractControl): ValidationErrors | null {
		try {
			if (control.value === 'NA' || !control.value) {
				return null;
			}
			Cycle.fromIdentifier(control.value);
			return null;
		} catch (error) {
			if (error instanceof InvalidCycleIdentifierError) {
				return {invalidAiracFormat: {value: control.value}};
			}
			return null;
		}
	}

	public static validIcaoCountriesString = (possibleIcaoCountries: ICAOCountry[]) => {
		return (c: AbstractControl): ValidationErrors | null => {
			if (typeof c.value === 'string') {
				const countries = c.value.split(',').map(v => v.trim());
				const unknownCountries = countries.filter(country => {
					const foundIcaoCountries = possibleIcaoCountries.filter(icaoCountry => icaoCountry.code.toLowerCase() === country.toLowerCase());
					return foundIcaoCountries.length === 0;
				});
				if (unknownCountries.length > 0) {
					return {unknownIcaoCounties: unknownCountries};
				}
			}

			return null;
		};
	};

	public static requireNonEmptyArray(control: AbstractControl): ValidationErrors {
		if (!control.value || !Array.isArray(control.value) || control.value.length === 0) {
			return {required: true};
		}
		return null;
	}

	public static minLengthArray = (min: number) => {
		return (c: AbstractControl): ValidationErrors | null => {
			if (c.value.length >= min)
				return null;

			return {minLengthArray: true};
		};
	};
}
