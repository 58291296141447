import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ProductionCatalogueService} from '../_service/production-catalogue.service';
import {combineLatest, map, Observable, of, startWith, Subject, Subscription, switchMap} from 'rxjs';
import {Page} from '../../shared/pagination/page';
import {Header} from '../../shared/table/header';
import {ProductionCatalogueOverview} from '../_model/production-catalogue-overview';
import {CreateProductionCatalogueRequest} from '../_model/create-production-catalogue-request';
import {ToastrMessageType} from '../../shared/toastr/toastr.message.type';
import {ToastrMessageService} from '../../shared/toastr/toastr.message.service';
import {TranslateService} from '@ngx-translate/core';
import {ProductionCatalogue} from '../_model/production-catalogue';
import {UpdateProductionCatalogueRequest} from '../_model/update-production-catalogue-request';
import {formatDate} from '@angular/common';
import {ContentArray} from '../../shared/model/content-array';
import {DropdownData} from '../../shared/dropdown-multiselect/dropdown-data';
import {DropdownDataFactory} from '../../shared/dropdown-multiselect/dropdown-data.factory';
import {LaunchProductionCatalogueRequest} from '../_model/launch-production-catalogue-request';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
	selector: 'app-production-catalogue-overview',
	templateUrl: './production-catalogue-overview.component.html',
	styleUrls: ['./production-catalogue-overview.component.css']
})
export class ProductionCatalogueOverviewComponent implements OnInit, OnDestroy {
	productionCatalogue$: Observable<ProductionCatalogue>;
	page$: Observable<Page<ProductionCatalogueOverview>>;
	headers: Header[] = [
		{name: 'status'},
		{name: 'approvalDate'},
		{name: 'identification'},
		{name: 'airac'},
		{name: 'requester'},
		{name: 'project'},
		{name: 'articlesProduced'}
	];
	private refresh$ = new Subject<void>();
	private currentPage$ = new Subject<number>();
	selectedFilterCriteria = {} as ContentArray;
	searchValue = '';
	filterDropdownData$: Observable<DropdownData[]>;
	openedDropdown: number | null = null;
	private subscription = new Subscription();
	productionCatalogueUuid: string | null = null;
	isCreating = false;
	isEditing = false;
	isCopying = false;
	isLaunching = false;
	isViewing = false;

	constructor(private readonly productionCatalogueService: ProductionCatalogueService,
				private readonly toastrMessageService: ToastrMessageService,
				private readonly dropdownDataFactory: DropdownDataFactory,
				private readonly translateService: TranslateService,
				private readonly router: Router,
				private readonly route: ActivatedRoute) {
	}

	ngOnInit(): void {
		const state = window.history.state as { mode?: string; readonly?: boolean };

		if (state) {
			this.isCreating = state.mode === 'create';
			this.isViewing = state.mode === 'detail' && state.readonly === true;
			this.isEditing = state.mode === 'detail' && state.readonly === false;
			this.isCopying = state.mode === 'copy';
			this.isLaunching = state.mode === 'launch';
		}

		this.route.params.subscribe((params): void => {
			this.productionCatalogueUuid = params['uuid'];
			if (!this.productionCatalogueUuid) return;

			this.productionCatalogueService.get(this.productionCatalogueUuid).subscribe((prodCat): void => {
				if (this.isCopying) {
					prodCat = {
						...prodCat,
						uuid: undefined,
						comments: [],
						status: { status: 'REDACTION_IN_PROGRESS', nextStatuses: [] },
						approvalDate: undefined,
						identification: this.translateService.instant('general.copy-of') + prodCat.identification
					};
				}
				this.productionCatalogue$ = of(prodCat);
			});
		});

		this.page$ = combineLatest([this.currentPage$.pipe(startWith(1)), this.refresh$.pipe(startWith(''))])
			.pipe(
				switchMap(([currentPage]) => this.productionCatalogueService.search(currentPage - 1, this.selectedFilterCriteria, this.searchValue))
			);

		this.filterDropdownData$ = this.refresh$.pipe(
			startWith(''),
			switchMap(() => this.productionCatalogueService.getFilterCriteria()),
			map(filterCriteria => filterCriteria.map(criteria => this.dropdownDataFactory.createDropdownData(criteria)))
		);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	loadPage(page: number): void {
		this.currentPage$.next(page);
	}

	openCreatePage(): void {
		this.router.navigate(['/production-catalogues/create'], {
			state: { mode: 'create' }
		});
	}

	closePage(): void {
		this.router.navigate(['production-catalogue']);
	}

	createCatalogue(request: CreateProductionCatalogueRequest): void {
		this.subscription.add(this.productionCatalogueService.create(request).subscribe((productionCatalogue: ProductionCatalogue) => {
			this.closePage();
			this.toastrMessageService.show({
				type: ToastrMessageType.SUCCESS,
				messageHTML: `<i class="ti ti-check me-2 fs-5"></i> ${this.translateService.instant('production-catalogue.create.success-message')}`
			});
			this.openViewPage(productionCatalogue.uuid);
		}));
	}

	updateCatalogue(request: UpdateProductionCatalogueRequest): void {
		this.subscription.add(this.productionCatalogueService.update(request.uuid, request).subscribe(() => {
			this.closePage();
			this.toastrMessageService.show({
				type: ToastrMessageType.SUCCESS,
				messageHTML: `<i class="ti ti-check me-2 fs-5"></i> ${this.translateService.instant('production-catalogue.edit.success-message')}`
			});
			this.refresh$.next();
		}));
	}

	deleteCatalogue(uuid: string): void {
		this.subscription.add(this.productionCatalogueService.delete(uuid)
			.subscribe({
				next: () => {
					this.toastrMessageService.show({
						type: ToastrMessageType.SUCCESS,
						messageHTML: `<i class="ti ti-trash me-2 fs-5"></i> ${this.translateService.instant('production-catalogue.delete.success-message')}`
					});
					this.refresh$.next();
					this.closePage();
				},
				error: (errorResponse) => {
					this.toastrMessageService.show({
						type: ToastrMessageType.ERROR,
						messageHTML: errorResponse.error.errorCode
					});
				}
			}));
	}

	launchCatalogue(request: LaunchProductionCatalogueRequest): void {
		this.subscription.add(this.productionCatalogueService.launch(request.uuid, request).subscribe(() => {
			this.closePage();
			this.toastrMessageService.show({
				type: ToastrMessageType.SUCCESS,
				messageHTML: `<i class="ti ti-check me-2 fs-5"></i> ${this.translateService.instant('production-catalogue.launch.success-message')}`
			});
			this.refresh$.next();
		}));
	}

	getConfirmationMessage(): string {
		return `<p>${this.translateService.instant('production-catalogue.delete.confirmation-message.are-you-sure')}</p>`;
	}

	openViewPage(uuid: string): void {
		this.router.navigate(['/production-catalogues/detail', uuid], {
			state: { mode: 'detail', readonly: true }
		});
	}

	openEditPage(uuid: string): void {
		this.router.navigate(['/production-catalogues/detail', uuid], {
			state: { mode: 'detail', readonly: false }
		});
	}

	openCopyPage(uuid: string): void {
		this.router.navigate(['/production-catalogues/copy', uuid], {
			state: { mode: 'copy' }
		});
	}

	openLaunchPage(uuid: string): void {
		this.router.navigate(['/production-catalogues/launch', uuid], {
			state: { mode: 'launch' }
		});
	}

	exportProductionCatalogue(productionCatalogue: ProductionCatalogueOverview): void {
		const subscription = this.productionCatalogueService.export(productionCatalogue.uuid).subscribe(response => {
			const a = document.createElement('a');
			a.download = productionCatalogue.identification + '_' + formatDate(Date.now(), 'yyyy-MM-dd_HH-mm-ss', 'en') + '.pdf';
			a.href = window.URL.createObjectURL(response.body);
			a.click();
			subscription.unsubscribe();
		});
	}

	exportProductionCatalogueArticles(productionCatalogue: ProductionCatalogueOverview): void {
		this.productionCatalogueService.downloadExportArticles(productionCatalogue.uuid, productionCatalogue.identification);
	}

	search(searchValue: any): void {
		this.searchValue = searchValue;
		this.loadPage(1);
	}

	filtersSelected(event: ContentArray): void {
		this.selectedFilterCriteria = {};
		Object.keys(event).forEach(key => this.selectedFilterCriteria[key] = event[key]);
		this.loadPage(1);
	}

	hasSearchCriteria(): boolean {
		return !!this.searchValue || Object.keys(this.selectedFilterCriteria).length > 0;
	}

	toggleDropdown(index: number): void {
		this.openedDropdown = this.openedDropdown === index ? null : index;
	}

	@HostListener('document:click', ['$event'])
	onDocumentClick(event: MouseEvent): void {
		const target = event.target as HTMLElement;

		if (!target.closest('.dropbtn-dropdown')) {
			this.openedDropdown = null;
		}
	}
}
