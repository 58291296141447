<ng-container *ngIf="parent.layout === 'stack'">
	<div class="layout-wrapper mb-4">
		<ng-container *ngTemplateOutlet="labelWrapper"/>
		<ng-container *ngTemplateOutlet="inputWrapper"/>
	</div>
</ng-container>

<ng-container *ngIf="parent.layout === 'stack-inline'">
	<div class="layout-wrapper mb-4 stack-inline">
		<ng-container *ngTemplateOutlet="labelWrapper"/>
		<ng-container *ngTemplateOutlet="inputWrapper"/>
	</div>
</ng-container>

<ng-container *ngIf="parent.layout === 'flex'">
	<div class="layout-wrapper d-flex justify-content-between gap-2 mb-4">
		<ng-container *ngTemplateOutlet="labelWrapper"/>
		<ng-container *ngTemplateOutlet="inputWrapper"/>
	</div>
</ng-container>

<ng-container *ngIf="parent.layout === 'flex-inline'">
	<div class="layout-wrapper d-flex justify-content-between gap-2 flex-inline">
		<ng-container *ngTemplateOutlet="labelWrapper"/>
		<ng-container *ngTemplateOutlet="inputWrapper"/>
	</div>
</ng-container>

<ng-template #labelWrapper>
	<div class="label-wrapper d-flex align-items-center" *ngIf="!parent.hideLabel">
		<label [for]="parent.id()" class="form-label"
			   [class.mb-0]="parent.layout === 'flex' || parent.layout === 'flex-inline'">{{ this.parent.translateLabel() }}</label>
		<i *ngIf="parent.showTooltip" id="{{parent.id() + '-tooltip'}}"
		   class="ti ti-alert-circle fs-5 mx-1"
		   [ngbTooltip]="this.parent.translateTooltip()"></i>
	</div>
</ng-template>

<ng-template #inputWrapper>
	<div class="input-wrapper">
		<ng-content/>

		<div class="invalid-feedback" [id]="parent.id() + '-invalid-feedback'" *ngIf="parent.hasErrors()">
            <span *ngFor="let error of parent.getErrors()">
                {{ parent.translateError(error) }}
            </span>
		</div>
	</div>
</ng-template>
