<div class="d-flex flex-column p-5">
	<div *ngIf="isCreating">
		<app-article-form-page
			(closePage)="closePage()">
		</app-article-form-page>
	</div>

	<div *ngIf="isViewing && article$ | async as article">
		<app-article-form-page
			(closePage)="closePage()"
			[article]="article"
			[readonly]="true">
		</app-article-form-page>
	</div>

	<div *ngIf="isEditing && article$ | async as article">
		<app-article-form-page
			[article]="article"
			(closePage)="closePage()"
		></app-article-form-page>
	</div>

	<ng-container *ngIf="!isCreating && !isViewing && !isEditing">
		<div class="d-flex mb-3">
			<h1>{{ 'article.overview.title' | translate }}</h1>
		</div>
		<div *ngIf="page$ | async as page">
			<div *ngIf="page.totalElements > 0 || hasSearchCriteria(); else noArticles">
				<div class="d-inline-flex flex-row justify-content-between w-100 mb-3">
					<div class="d-inline-flex gap-2">
						<app-multilevel-dropdown-multiselect [providedData]="filterDropdownData$ | async"
															 (selection)="filtersSelected($event)"
															 translationPrefix="article.overview.filter."
															 placeholder="general.filter"
															 placeholderIcon="ti-filter">
						</app-multilevel-dropdown-multiselect>

						<app-search inputFieldId="article-search-field"
									placeHolder="article.overview.search.placeholder"
									(searchEvent)="search($event)">
						</app-search>
					</div>

					<div class="d-inline-flex gap-2">
						<button id="fms-download" class="btn btn-secondary" *permissionsAllowed="['DOWNLOAD_FMS_CONFIG']"
								(click)="downloadFMSConfig()">
							<i class="ti ti-download me-2 fs-4"></i>{{ 'article.overview.download-fms-config-button' | translate }}
						</button>

						<a [routerLink]="'/fmsupload'" class="btn btn-primary" *permissionsAllowed="['UPLOAD_FMS_CONFIG']">
							<i class="ti ti-upload me-2 fs-4"></i>{{ 'article.overview.upload-fms-config-button' | translate }}
						</a>

						<button id="open-create-article" *permissionsAllowed="['CREATE_ARTICLES']" type="button"
								class="btn btn-primary" (click)="openCreatePage()">
							<i class="ti ti-plus me-2 fs-4"></i>{{ 'article.overview.create-button' | translate }}
						</button>
					</div>
				</div>

				<ng-container *ngIf="articlesWithMissingProperties$ | async as count">
					<div *ngIf="count>0" class="alert alert-danger alert-dismissible fade show" role="alert">
						<span [innerHTML]="'article.overview.articles-with-missing-properties' | translate"></span>
					</div>
				</ng-container>

				<app-table [id]="'article-table'"
						   [headers]="headers"
						   [headerNamePrefix]="'article.overview.headers.'"
						   [addButtonColumn]="true">
					<ng-container *ngIf="page.totalElements > 0 else noResults">
						<tr *ngFor="let article of page.content; let i  = index"
							[class.missing-properties]="doesArticleMissProperties(article)">
							<td>{{ article.reference }}</td>
							<td>{{ article.usualDesignation }}</td>
							<td>{{ article.type }}</td>
							<td>
								{{ getProgramsCommaSeperated(article.programs) }}
							</td>
							<td *permissionsAllowed="['VIEW_ARTICLE_PARAMETERS']">{{ article.offset }}</td>
							<td *permissionsAllowed="['VIEW_ARTICLE_PARAMETERS']">{{ article.linkedDatabases }}</td>
							<td *permissionsAllowed="['VIEW_ARTICLE_PARAMETERS']">{{ article.subscribedCustomers }}</td>
							<td *permissionsAllowed="['VIEW_ARTICLE_PRODUCTION_PARAMETERS']">{{ article.lastUploaded }}</td>
							<td *permissionsAllowed="['VIEW_ARTICLE_PRODUCTION_PARAMETERS']">{{ article.flightManagementSystem?.name }}</td>
							<td *permissionsAllowed="['VIEW_ARTICLE_PRODUCTION_PARAMETERS']">{{ getProjectsCommaSeperated(article.projects) }}</td>
							<td *permissionsAllowed="['VIEW_ARTICLE_PRODUCTION_PARAMETERS']">
								<span class="badge list-group-item-info cursor-default me-2 mb-1"
									  [class.badge-bg-green]="article.articleProductionStatus ==='READY'"
									  [class.badge-bg-red]="article.articleProductionStatus ==='UNAVAILABLE_FOR_PRODUCTION'"
									  [class.badge-bg-orange]="article.articleProductionStatus ==='DQR_REFERENCE_NOT_FOUND' || article.articleProductionStatus === 'ARTICLE_REFERENCE_CREATION_IN_PROGRESS'">
									{{ 'article.productionStatus.' + article.articleProductionStatus | translate }}
								</span>
							</td>
							<td *permissionsAllowed="['VIEW_ARTICLE_PRODUCTION_PARAMETERS']">
								{{ article.sourceRadicalIdentifier ? article.sourceRadicalIdentifier.name : '-' }}
							</td>
							<td>
								<div class="d-flex justify-content-end align-items-center">
									<i id="edit-article-{{i}}" *permissionsAllowed="['UPDATE_ARTICLES']"
									   class="ti ti-pencil me-2 fs-4 pointer" (click)="openEditPage(article.uuid)"></i>
									<i id="delete-article-{{i}}" *permissionsAllowed="['DELETE_ARTICLES']"
									   class="ti ti-trash text-danger me-2 fs-4 pointer"
									   appConfirmationModal
									   (confirmAction)="deleteArticle(article)"
									   confirmationMessage="{{getConfirmationMessage() }}">
									</i>
									<i id="view-article-{{i}}" class="ti ti-eye me-2 fs-4 pointer"
									   (click)="openViewPage(article.uuid)"></i>
								</div>
							</td>
						</tr>
					</ng-container>
				</app-table>
				<app-pagination [page]="page"
								(pageEvent)="loadPage($event)"></app-pagination>
				<div class="d-inline-flex flex-row justify-content-end w-100 mb-3 mt-3" *permissionsAllowed="['EXPORT_ARTICLES']">
					<app-direct-download [exportFunction]="getExportFunction()"
										 [filePrefix]="'article_overview'"></app-direct-download>
				</div>
			</div>
		</div>
	</ng-container>
</div>

<ng-template #noResults>
	<tr>
		<td colspan="100%">
			<p class="empty-state-text my-4 justify-content-center">{{ 'article.overview.search.no-results' | translate }}</p>
		</td>
	</tr>
</ng-template>

<ng-template #noArticles>
	<div class="d-flex flex-column w-100 h-100 align-items-center justify-content-center">
		<p class="empty-state-text mb-4">{{ 'article.overview.no-content' | translate }}</p>
		<button id="open-create-article-modal-no-articles" class="btn btn-primary" (click)="openCreatePage()" *permissionsAllowed="['CREATE_ARTICLES']">
			<i class="ti ti-plus me-2 fs-4"></i>{{ 'article.overview.create-button' | translate }}
		</button>
	</div>
</ng-template>
