import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Article} from '../_model/article';
import {ArticleService} from '../_service/article.service';
import {CreateOrUpdateArticleRequest} from '../_model/create-or-update-article-request';
import {finalize, Observable, tap} from 'rxjs';

@Component({
	selector: 'app-article-form-page',
	templateUrl: './article-form-page.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticleFormPageComponent {

	@Input() article: Article;
	@Input() readonly = false;
	@Output() closePage: EventEmitter<void> = new EventEmitter();

	submitting = false;

	constructor(private articleService: ArticleService) {
	}

	get titleTranslationKey(): string {
		if (this.readonly) {
			return 'article.view.modal.title';
		}
		return this.article ? 'article.update.modal.title' : 'article.create.modal.title';
	}

	getSubmitTranslationKey(): string {
		return this.article ? 'general.save' : 'general.create';
	}

	submitForm(article: CreateOrUpdateArticleRequest): void {
		this.doSubmit(article).subscribe();
	}

	close(): void {
		this.closePage.emit();
	}

	private doSubmit(article: CreateOrUpdateArticleRequest): Observable<void> {
		this.submitting = true;

		return this.submit(article)
			.pipe(
				tap(() => {
					this.submitting = false;
					this.close();
				}),
				finalize(() => this.submitting = false)
			);
	}

	private submit(request: CreateOrUpdateArticleRequest): Observable<void> {
		if (this.article) {
			return this.articleService.update(this.article.uuid, request);
		}
		return this.articleService.create(request);
	}
}
