import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {DndModule} from 'ngx-drag-drop';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ValidatedInputModule} from '../shared/validated-input/validated-input.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TableModule} from '../shared/table/table.module';
import {PaginationModule} from '../shared/pagination/pagination.module';
import {PermissionsModule} from '../shared/permission/permissions.module';
import {ModalModule} from '../shared/modal/modal.module';
import {DropdownModule} from '../shared/dropdown-multiselect/dropdown.module';
import {ArticleService} from './_service/article.service';
import {ArticleOverviewComponent} from './overview/article-overview.component';
import {ArticleFormComponent} from './article-form/article-form.component';
import {ModularFormsModule} from '../shared/modular-forms/modular-forms.module';
import {ErrorHandlingModule} from '../shared/error-handling/error-handling.module';
import {DownloadModule} from '../shared/download/download.module';
import {SearchModule} from '../shared/search/search.module';
import {RouterModule} from '@angular/router';
import {ArticleFormPageComponent} from './article-form-page/article-form-page.component';
import {IcaoModule} from '../icao/icao.module';

@NgModule({
	declarations: [
		ArticleOverviewComponent,
		ArticleFormComponent,
		ArticleFormPageComponent
	],
	imports: [
		TranslateModule,
		DndModule,
		CommonModule,
		ReactiveFormsModule,
		ValidatedInputModule,
		NgbModule,
		TableModule,
		PaginationModule,
		PermissionsModule,
		ModalModule,
		FormsModule,
		DropdownModule,
		ModularFormsModule,
		ErrorHandlingModule,
		DownloadModule,
		SearchModule,
		RouterModule,
		IcaoModule
	],
	exports: [
		ArticleFormComponent,
		ArticleFormPageComponent
	],
	providers: [
		ArticleService
	]
})
export class ArticleModule {

}
