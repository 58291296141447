<div class="container">
	<div class="py-3">
		<div class="status d-flex">
			<ng-container *ngFor="let productionStep of productionSteps; let i = index; let last = last">

				<div class="state state--in-progress" id="tooltip-in-progress" ngbTooltip="{{ productionStep.productionStepName | titlecase }}" placement="auto" tooltipClass="text-center"
					 *ngIf="productionStep.status === launchArticleProductionStepStatus.IN_PROGRESS">
					<div class="indicator">
						{{ productionStep.position }}
					</div>
					<div class="type">{{ productionStep.productionStepShortName }}</div>
				</div>

				<div class="state state--default" id="tooltip-default" ngbTooltip="{{ productionStep.productionStepName | titlecase }}" placement="auto" tooltipClass="text-center"
					 *ngIf="productionStep.status === launchArticleProductionStepStatus.UNKNOWN ">
					<div class="indicator">
						{{ productionStep.position }}
					</div>
					<div class="type">{{ productionStep.productionStepShortName }}</div>
				</div>
				<div class="state state--success" id="tooltip-success" ngbTooltip="{{ productionStep.productionStepName | titlecase }}" placement="auto" tooltipClass="text-center"
					 *ngIf="productionStep.status === launchArticleProductionStepStatus.SUCCESS">
					<div class="indicator">
						<i class="ti ti-check"></i>
					</div>
					<div class="type">{{ productionStep.productionStepShortName }}</div>
				</div>
				<div class="state state--warning" id="tooltip-warning" ngbTooltip="{{ productionStep.productionStepName | titlecase }}" placement="auto" tooltipClass="text-center"
					 *ngIf="productionStep.status === launchArticleProductionStepStatus.WARNING">
					<div class="indicator">
						<i class="ti ti-check"></i>
					</div>
					<div class="type">{{ productionStep.productionStepShortName }}</div>
				</div>
				<div class="state state--danger" id="tooltip-danger" ngbTooltip="{{ productionStep.productionStepName | titlecase }}" placement="auto" tooltipClass="text-center"
					 *ngIf="productionStep.status === launchArticleProductionStepStatus.FAIL">
					<div class="indicator">
						<i class="ti ti-exclamation-mark"></i>
					</div>
					<div class="type">{{ productionStep.productionStepShortName }}</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>
