import {ProgramDescriptor} from '../../notices/program/program-descriptor';
import {Project} from '../../project/_model/project';
import {SourceRadicalIdentifier} from '../../sourceradicalidentifier/_model/source-radical-identifier';
import {GeographicalLocation} from './geographical-location';

export class Article {
	uuid: string;
	reference: string;
	usualDesignation: string;
	type: string;
	programs: ProgramDescriptor[];
	offset: number;
	flightManagementSystemIdentifier: string;
	tailoredCode?: string;
	geoAreas?: string[];
	geographicalLocations?: GeographicalLocation[];
	regionalCode?: string;
	regionalCodeDefinition?: string;
	icaoCountries?: string[];
	projects?: Project[];
	articleProductionStatus: string;
	canEditArticleReference: boolean;
	canEditProductionParameters: boolean;
	sourceRadicalIdentifier: SourceRadicalIdentifier;
	databaseRadicalIdentifier: string;
	rnpAr?: string;
	exportControl?: string;
	lpv: boolean;
}
