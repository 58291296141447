import {NgModule} from '@angular/core';
import {ICAOService} from './_service/icao.service';

@NgModule({
	providers:[
		ICAOService
	]
})
export class IcaoModule {

}
