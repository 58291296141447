export interface LaunchArticleProductionStep {
	name: string;
	status: string;
	latestUpdateTimestamp: string;
}

export enum ProductionStepName {
	PREPARATION = 'PREPARATION',
	PRODUCTION = 'PRODUCTION',
	VALIDATION = 'VALIDATION',
	UPLOAD = 'UPLOAD'
}

export enum ProductionStepShortName {
	PREP = 'PREP',
	PROD = 'PROD',
	VAL = 'VAL',
	UPL = 'UPL',
}

export enum ProductionStepStatus {
	IN_PROGRESS = 'IN_PROGRESS',
	SUCCESS = 'SUCCESS',
	FAIL = 'FAIL',
	WARNING = 'WARNING',
	UNKNOWN = 'UNKNOWN'
}
