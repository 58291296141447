import {Component, OnInit} from '@angular/core';
import {Page} from '../../shared/pagination/page';
import {combineLatest, Observable, startWith, Subject, switchMap} from 'rxjs';
import {Header} from '../../shared/table/header';
import {ProductionCatalogueService} from '../_service/production-catalogue.service';
import {LaunchModalData} from '../launch-modal-data';
import {LaunchArticle} from '../_model/launch-article';

@Component({
	selector: 'app-launch-modal',
	templateUrl: './launch-modal.component.html'
})
export class LaunchModalComponent implements OnInit {
	private PAGE_SIZE = 8;
	launchModalData: LaunchModalData;
	page$: Observable<Page<LaunchArticle>>;
	headers: Header[] = [
		{name: 'article'},
		{name: 'databaseRadId'},
		{name: 'status'},
		{name: 'logs'},
		{name: 'lastChanged'}
	];
	private refresh$ = new Subject<void>();
	private currentPage$ = new Subject<number>();

	constructor(private readonly productionCatalogueService: ProductionCatalogueService) {
	}

	ngOnInit(): void {
		this.page$ = combineLatest([
			this.currentPage$.pipe(startWith(1)),
			this.refresh$.pipe(startWith(''))
		]).pipe(
			switchMap(([currentPage]) => this.productionCatalogueService.getLaunchArticles(currentPage - 1, this.launchModalData.prodCatUuid, this.launchModalData.launchUuid, this.PAGE_SIZE))
		);
	}

	loadPage(page: number): void {
		this.currentPage$.next(page);
	}
}
