<div class="table-responsive my-3" [ngStyle]="{'height': (removeStickyHeader) ? 'unset' : viewHeight }">
	<table class="table m-0" id="{{id}}" aria-describedby="table">
		<thead *ngIf="headers.length > 0" class="table-header">
		<tr>
			<ng-container *ngFor="let header of headers ; let index">
				<ng-container *ngIf="header.visible">
					<th id="{{header.name}}-header"
						*permissionsAllowed="getPermissions(header)">{{headerNamePrefix + header.name | translate}}</th>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="addButtonColumn">
				<th *ngFor="let i of getNrOfButtonColumns()"></th>
			</ng-container>
		</tr>
		<tr *ngIf="hasSearchableHeaders()" class="table-search">
			<ng-container *ngFor="let header of headers">
				<td *ngIf="header.visible" class="px-1">
					<ng-container *ngIf="header.searchable">
						<app-search inputFieldId="{{header.name}}-search-field"
                                    [resetSearch]="resetSearch"
									(searchEvent)="updateSearch(header.name, $event)"
						></app-search>
					</ng-container>
				</td>
			</ng-container>
		</tr>
		</thead>
		<tbody>
		<ng-content>
		</ng-content>
		</tbody>
	</table>
</div>
