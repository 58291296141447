import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductionStepComponent} from './production-step/production-step.component';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
	declarations: [
		ProductionStepComponent
	],
	exports: [
		ProductionStepComponent
	],
	imports: [
		CommonModule,
		TranslateModule,
		NgbTooltip
	]
})
export class LaunchArticleModule {
}
