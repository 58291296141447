import {Injectable, OnDestroy} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import {ICAOCountry} from '../_model/icao-country';

@Injectable({
	providedIn: 'root'
})
export class ICAOService implements OnDestroy {

	private allCountries$ = new ReplaySubject<Array<ICAOCountry>>(1);
	private allCountries: ICAOCountry[];
	subscription = new Subscription();
	readonly baseUrl = environment.backend_url + '/icao';

	constructor(private http: HttpClient) {
		this.subscription.add(this.http.get<Array<ICAOCountry>>(this.baseUrl + '/country')
			.subscribe(allCountries => {
				this.allCountries$.next(allCountries);
				this.allCountries = allCountries;
			})
		);
	}

	getAllCountries(): Observable<Array<ICAOCountry>> {
		return this.allCountries$.asObservable();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

}
