import {Observable} from 'rxjs';
import {Page} from '../../shared/pagination/page';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {ProductionCatalogueOverview} from '../_model/production-catalogue-overview';
import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {CreateProductionCatalogueRequest} from '../_model/create-production-catalogue-request';
import {ProductionCatalogue} from '../_model/production-catalogue';
import {UpdateProductionCatalogueRequest} from '../_model/update-production-catalogue-request';
import {formatDate} from '@angular/common';
import {ContentArray} from '../../shared/model/content-array';
import {FilterCriteria} from '../../shared/model/filter-criteria';
import {CreateOrUpdateCommentRequest} from '../_model/create-or-update-comment.request';
import {LaunchProductionCatalogueRequest} from '../_model/launch-production-catalogue-request';
import {LaunchProductionArticle} from '../_model/launch-production-article';
import {Launch} from '../_model/launch';
import {LaunchArticle} from '../_model/launch-article';

@Injectable()
export class ProductionCatalogueService {

	private baseUrl = environment.backend_url + '/productioncatalogue';

	constructor(private http: HttpClient) {
	}

	search(pageNumber: number, contentSearchCriteria: ContentArray, searchValue: string): Observable<Page<ProductionCatalogueOverview>> {
		let params = new HttpParams();
		params = params.append('page', pageNumber);
		if (searchValue) {
			params = params.append('search.*', searchValue);
		}
		Object.keys(contentSearchCriteria)
			.forEach(key => contentSearchCriteria[key]
				.forEach(value => params = params.append('filter.' + key, value)));
		return this.http.get<Page<ProductionCatalogueOverview>>(this.baseUrl + '/search', {params: params});
	}

	create(request: CreateProductionCatalogueRequest): Observable<ProductionCatalogue> {
		return this.http.post<ProductionCatalogue>(this.baseUrl, request);
	}

	update(uuid: string, request: UpdateProductionCatalogueRequest): Observable<ProductionCatalogue> {
		return this.http.put<ProductionCatalogue>(`${this.baseUrl}/${uuid}`, request);
	}

	launch(uuid: string, request: LaunchProductionCatalogueRequest): Observable<void> {
		return this.http.post<void>(`${this.baseUrl}/${uuid}/launch`, request);
	}

	createOrUpdateComment(uuid: string, request: CreateOrUpdateCommentRequest): Observable<void> {
		return this.http.put<void>(`${this.baseUrl}/${uuid}/comment`, request);
	}

	deleteComment(uuid: string, commentId: string): Observable<void> {
		return this.http.delete<void>(`${this.baseUrl}/${uuid}/comment/${commentId}`);
	}

	get(uuid: string): Observable<ProductionCatalogue> {
		return this.http.get<ProductionCatalogue>(`${this.baseUrl}/${uuid}`);
	}

	delete(uuid: string): Observable<void> {
		return this.http.delete<void>(`${this.baseUrl}/${uuid}`);
	}

	export(uuid: string): Observable<HttpResponse<Blob>> {
		return this.http.get(this.baseUrl + '/' + uuid + '/export', {observe: 'response', responseType: 'blob'});
	}

	exportArticles(uuid: string): Observable<HttpResponse<Blob>> {
		return this.http.get(this.baseUrl + '/' + uuid + '/exportarticles', {observe: 'response', responseType: 'blob'});
	}

	downloadExport(uuid: string, identification: string): void {
		const subscription = this.export(uuid).subscribe(response => {
			const a = document.createElement('a');
			a.download = identification + '_' + formatDate(Date.now(), 'yyyy-MM-dd_HH-mm-ss', 'en') + '.pdf';
			a.href = window.URL.createObjectURL(response.body);
			a.click();
			subscription.unsubscribe();
		});
	}

	downloadExportArticles(uuid: string, identification: string): void {
		const subscription = this.exportArticles(uuid).subscribe(response => {
			const a = document.createElement('a');
			a.download = identification + '_' + formatDate(Date.now(), 'yyyy-MM-dd_HH-mm-ss', 'en') + '.csv';
			a.href = window.URL.createObjectURL(response.body);
			a.click();
			subscription.unsubscribe();
		});
	}

	getFilterCriteria(): Observable<FilterCriteria[]> {
		return this.http.get<FilterCriteria[]>(`${this.baseUrl}/filtercriteria`);
	}

	getLinkedArticles(uuid: string): Observable<LaunchProductionArticle[]> {
		return this.http.get<LaunchProductionArticle[]>(`${this.baseUrl}/${uuid}/articles`);
	}

	getLaunchHistory(pageNumber: number, uuid: string): Observable<Page<Launch>> {
		let params = new HttpParams();
		params = params.append('page', pageNumber);
		return this.http.get<Page<Launch>>(`${this.baseUrl}/${uuid}/search-launches`, {params});
	}

	getLaunchArticles(pageNumber: number, uuid: string, launchUuid: string, pageSize: number): Observable<Page<LaunchArticle>> {
		let params = new HttpParams();
		params = params.append('page', pageNumber).append('pageSize', pageSize);
		return this.http.get<Page<LaunchArticle>>(`${this.baseUrl}/${uuid}/launch/${launchUuid}/search-launch-articles`, {params});
	}
}

